<!--suppress ALL -->
<template>
  <div class="container-fluid mt-4">
    <router-link to="/devices" class="btn btn-info mb-4">&lt; Inapoi la lista</router-link>
    <form v-if="device" @submit.prevent="save">
      <div class="mb-3">
        <label>Name</label>
        <input v-model="device.name" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Identifier</label>
        <input v-model="device.identifier" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Location</label>
        <vue-autosuggest
          ref="select"
          :suggestions="[{ data: searchResults }]"
          :value="device.name"
          @input="onSearch"
          @item-changed="fetchPlaceDetails($event)"
          :get-suggestion-value="(s) => s.item.label"
          :input-props="{id:'autosuggest__input', style: 'width:100%;height: 38px;border: 1px solid #ccc;padding: 0 15px', placeholder:'Introduce-ți adresa (ex.: 13 Calea București)'}">
          <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
            <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.label}}</div>
          </div>
        </vue-autosuggest>
      </div>
      <button type="submit" class="btn btn-success">SAVE</button>
    </form>
  </div>
</template>

<script>
import config from '@/config'
import { VueAutosuggest } from 'vue-autosuggest'
import _ from 'lodash'
export default {
  name: 'DeviceEdit',
  components: { VueAutosuggest },
  data () {
    return {
      device: {
        id: null,
        name: '',
        identifier: '',
        location: {
          lat: null,
          lng: null
        }
      },
      searchResults: []
    }
  },
  mounted () {
    if (this.$route.params.id !== '0') {
      this.$axios.get(config.baseApiUrl + '/api/devices/' + this.$route.params.id)
        .then((response) => {
          this.device = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    save () {
      this.$axios({
        method: this.$route.params.id === '0' ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/devices' + (this.$route.params.id === '0' ? '' : ('/' + this.$route.params.id)),
        data: this.device
      })
        .then((response) => {
          this.$toasted.success('Saved', {
            duration: 3000
          })
          if (this.$route.params.id === '0') {
            this.device = response.data
            this.$router.push({ name: 'DeviceEdit', params: { id: response.data.id } })
          }
        })
        .catch((error) => {
          this.$toasted.error(Object.values(error.response.data.errors).map(error => error.join('<br>')).join('<br>'))
        })
    },
    onSearch (search) {
      if (search.length) {
        this.search = search
        this.apiSearch(search, this)
      }
    },
    apiSearch: _.debounce((search, vm) => {
      vm.$axios.get('https://addr.ogo.taxi/places-search?input=' + search + '&language=ro&components=country:ro')
        .then((response) => {
          vm.searchResults = response.data.predictions.map((item) => {
            return {
              id: item.place_id,
              label: item.description
            }
          })
        })
    }, 500),
    fetchPlaceDetails (data) {
      if (data !== null) {
        this.$axios.get('https://addr.ogo.taxi/place-details?place_id=' + data.item.id + '&fields=geometry,formatted_address,address_component,name&language=ro')
          .then((response) => {
            this.device.name = response.data.result.formatted_address
            this.device.location.lat = response.data.result.geometry.location.lat
            this.device.location.lng = response.data.result.geometry.location.lng
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
