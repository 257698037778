<template>
  <div>
    <b-jumbotron class="mt-4">
      <template #header>Lista devaisuri</template>
      <hr class="my-4">
      <p>
        Acțiuni:
      </p>
      <div class="row">
        <div class="col-auto pr-4">
          <b-button variant="light" to="/device/0">Adaugă device</b-button>
        </div>
        <div class="col-auto">
          <b-input-group>
            <b-form-input v-model="filters.search" @keyup.enter="$refs.vuetable.refresh()"></b-form-input>
            <b-input-group-append>
              <b-button @click="$refs.vuetable.refresh()" variant="info">
                <b-icon-filter-left></b-icon-filter-left> Filter
              </b-button>
              <b-button @click="resetFilters" variant="danger">
                Reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </b-jumbotron>
    <Vuetable
      ref="vuetable"
      :css="css.table"
      :api-url="apiUrl"
      :append-params="apiFilters"
      :fields="tableFields"
      :http-fetch="fetchApiData"
      :transform="transformApiResponse"
      :per-page="15"
      pagination-path="meta"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" slot-scope="props">
        <b-button-group class="mx-1">
          <b-button size="sm" :to="'/device/' + props.rowData.id" variant="warning">Modifică</b-button>
          <b-button size="sm" @click="remove(props.rowData.id)" variant="danger">Șterge</b-button>
        </b-button-group>
      </template>
    </Vuetable>
    <div class="text-center d-flex justify-content-center mt-3">
      <VuetablePagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></VuetablePagination>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import CssConfig from '@/assets/other/VuetableBootstrap4Config'

export default {
  name: 'Devices',
  components: {
    Vuetable,
    VuetablePagination
  },
  data () {
    return {
      tableFields: [
        {
          name: 'id',
          title: 'ID'
        },
        {
          name: 'name',
          title: 'Name'
        },
        {
          name: 'identifier',
          title: 'Identifier'
        },
        {
          name: 'actions',
          title: 'Edit'
        }
      ],
      css: CssConfig,
      filters: {
        search: ''
      }
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/devices'
    },
    apiFilters () {
      return Object.keys(this.filters)
        .filter((k) => this.filters[k] !== null && this.filters[k] !== '')
        .reduce((a, k) => ({ ...a, [k]: this.filters[k] }), {})
    }
  },
  methods: {
    fetchApiData (apiUrl, httpOptions) {
      return this.$axios.get(apiUrl, httpOptions)
    },
    transformApiResponse (response) {
      return response
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/devices/' + id)
          .then(() => {
            this.$refs.vuetable.reload()
          })
      }
    },
    resetFilters () {
      for (const prop in this.filters) {
        this.filters[prop] = ''
      }
      this.$nextTick(() => {
        this.$refs.vuetable.refresh()
      })
    }
  }
}
</script>

<style scoped>
.table tr th,
.table tr td {
  vertical-align: middle;
}
</style>
